import React from 'react';
import {FeaturesSection,HeadingLayout,Heading,SubHeading,FeaturesRow,FeaturesWrapper,
    FeaturesCard,FeatureImgHolder,FeaturesCardIcon,
    TextLayout
} from './features.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const Features = () => {  
    const JSONData  = useStaticQuery(graphql`{
  realestatePage5Json {
    Features {
      Heading
      SubHeading
      FeaturesWrapper {
        FeaturesCardImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        FeaturesCardIcon
        FeaturesCardHeading
        FeaturesCardPara
      }
    }
  }
}
`);
    const FeaturesData = JSONData.realestatePage5Json.Features;

    return (
        <FeaturesSection id="featuresSection">
            <Container>
                
                <HeadingLayout>
                    <Heading>
                    {FeaturesData.Heading}
                    </Heading>
                    <SubHeading>
                    {FeaturesData.SubHeading}
                    </SubHeading>
                </HeadingLayout>

                <FeaturesWrapper>
                    <FeaturesRow>
                    { 
                        FeaturesData.FeaturesWrapper.map((item,idx) => {
                        return (
                            <Col lg="4" md="12" sm="12">
                                        <FeaturesCard>
                                            <FeatureImgHolder>
                                                <GatsbyImage
                                                    image={item.FeaturesCardImg.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    className="featureImg" />
                                            </FeatureImgHolder>
                                            <FeaturesCardIcon src={item.FeaturesCardIcon} alt=""/>
                                            <TextLayout>
                                                <h5>{item.FeaturesCardHeading}</h5>
                                                <p>{item.FeaturesCardPara}</p>
                                            </TextLayout>
                                        </FeaturesCard>
                                </Col>
                        );
                        })
                    }
                    </FeaturesRow>
                </FeaturesWrapper>
            </Container>
        </FeaturesSection>
    );
}
export default Features;