import styled from 'styled-components';
import {SectionHeading,SectionSubHeading,Commonbtn} from '../Common/common.style';
import {device} from '../Common/device';
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook';
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter';
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin';
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram';
import {Row} from 'react-bootstrap';

export const FooterSection = styled.section`
    background:#f5f5f5;
`;

export const FooterWrapper = styled.div`
    padding:100px 0px 0px;

    @media ${device.tablet}{
        padding:80px 10px 0px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:60px;
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:10px;
`;

export const SubHeading = styled(SectionSubHeading)`
`;

export const ContactLayout = styled.div`
    background-color:#fff;
    padding: 30px;
`;

export const FormLayout = styled.div`
    @media ${device.tablet}{{
        margin-bottom:40px;
    }
`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormGroup = styled.div`
    margin-bottom:15px;
`;

export const InputText = styled.input`
    width: 100%;
    background: #f5f5f5;
    border: none;
    padding: 8px 16px;
    outline:0;
    font-size: 15px;
`;

export const ContactSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextareaMessage = styled.textarea`
    width: 100%;
    background: #f5f5f5;
    border: none;
    padding: 8px 16px;
    height:100px;
    font-size: 15px;
    outline:0;
`;

export const TextCenter = styled.div`
    text-align:center;
`;

export const SubmitBtn = styled(Commonbtn)`
`;

export const ContactSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const ImageHolder = styled.div`
    .mapImage{
        height:300px;
    }
`;


export const FooterBottomLayout = styled.div`
    margin-top:40px;
    margin-bottom:20px;
`;

export const CompanyDetailsLayout = styled.div`

`;

export const CompanyLogo = styled.img`
    width:250px;
`;


export const ContactDetailsLayout = styled.div`
    
`;

export const DetailsLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:20px;

    p{
        margin-bottom:0px;
        margin-left:10px;
    }
`;

export const DetailsIcon = styled.img`
    width:30px;
    flex-shrink:0;
`;

export const FooterQuickLinksLayout = styled.div`
    background:#eee;
    padding:20px 0px;
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;
export const BottomFooterPara = styled.p`
    color:#6f6f6f;
    margin-bottom:0px;
    font-size:15px;
    text-align:left;

    @media ${device.laptop}{
        text-align:center;
        margin-bottom:10px;
    }
`;

export const BottomLink = styled.a`
    font-size:15px;   
    margin-bottom:0px;
    padding:10px 0px;  
    color:#6f6f6f;
    text-decoration:none;
    text-align:center;

    :hover{
        text-decoration:underline;
    }
`;


export const QuickLinksLayout = styled.div`
    
`;

export const FooterUl = styled.ul`
    text-align:center;
    margin:0;

    @media ${device.laptop}{
        margin-bottom:20px;
    }
`;

export const FooterLi = styled.li`
    list-style:none;
    display:inline-block;
    margin:0;
    margin-left:10px;

    a{
        color:#6f6f6f;
    }
`;

export const SocialLayout  = styled.div`
    text-align:right;

    @media ${device.laptop}{
        text-align:center;
    }
`;

export const FooterSocial = styled.a`
    margin-right:10px;
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #000;
    background: #bfca02;
    border-radius:100%;
    padding: 6px;
    transition:all .5s;

    :hover{
        background:#d8e302;
        color:#fff;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #000;
    background: #bfca02;
    border-radius:100%;
    padding: 6px;
    
    transition:all .5s;
    :hover{
        background:#d8e302;
        color:#fff;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #000;
    background: #bfca02;
    padding: 6px;
    border-radius:100%;
    
    transition:all .5s;
    :hover{
        background:#d8e302;
        color:#fff;
    }
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #000;
    background: #bfca02;
    padding: 6px;
    border-radius:100%;
    
    transition:all .5s;
    :hover{
        background:#d8e302;
        color:#fff;
    }
`;

