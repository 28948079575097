import React,{Component} from "react";
import {BannerSection,
    BannerSliderWrapper,
    ImgButtonLeft,
    LeftIcon,
    BannerSliderLayout,
    BannerImageHolder,
    BannerContents,
    BannerTextLayout,
    ImgButtonRight,
    RightIcon
} from './banner.style'
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Banner extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            fade:true,
            dots:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000
        };
        return (
            <BannerSection id="homeSection">
                <BannerSliderWrapper>
                    <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                        <LeftIcon />
                    </ImgButtonLeft>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                    {
                        this.props.BannerData.BannerSlides.map((item,idx)=>{
                            return (
                                <BannerSliderLayout>
                                    <BannerImageHolder>
                                        <GatsbyImage
                                            image={item.BannerImage.childImageSharp.gatsbyImageData}
                                            alt=""
                                            className="bannerImage" />
                                    </BannerImageHolder>
                                    <BannerContents>
                                        <BannerTextLayout>
                                            <h1>{item.BannerH3}</h1>
                                            <h6>{item.BannerH4}</h6>
                                        </BannerTextLayout>
                                    </BannerContents>
                                </BannerSliderLayout>
                            );
                        })
                    }
                    </Slider>
                    <ImgButtonRight onClick={this.next} aria-label="Next Button">
                        <RightIcon />
                    </ImgButtonRight>
                </BannerSliderWrapper>
            </BannerSection>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`{
  realestatePage5Json {
    Banner {
      BannerSlides {
        BannerImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        BannerH3
        BannerH4
      }
    }
  }
}
`}
        render={(data) => (
            <Banner BannerData={data.realestatePage5Json.Banner}/>
        )}
    />
    )