import {createGlobalStyle} from 'styled-components';
import {device} from './device';
const GlobalStyle = createGlobalStyle`


    body{
        font-family: 'Lato', sans-serif;
        font-weight: 400;

        .ReactModal__Overlay{
            z-index:9999 !important;
        }
    }

    h1,h2,h3,h4,h5{
        font-family: 'Titillium Web', sans-serif;
        font-weight:600;
    }
    h6,p{
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        color:#6f6f6f;
        margin-bottom:20px;
        font-size:15px;
        line-height:23px;
    }
    a{
        font-size:16px;
        font-family: 'Lato', sans-serif;
        font-weight:400;
    }
    img{
        margin-bottom:0px;
    }
    h1{
        font-size:70px;
        line-height:76px;
        text-align:center;
        margin-bottom:20px;
        color: #2f2f2f;
    
        @media ${device.laptop} {
            font-size: 54px;
            line-height: 60px;
        }
    
        @media ${device.tablet} {
            font-size: 46px;
            line-height: 52px;
        }
    
        @media ${device.mobileXL} {
            font-size:36px;
            line-height:42px;
        }
    }
    h2{
        font-size: 50px;
        line-height:56px;
        text-align:center;
        margin-bottom: 20px;
        color: #2f2f2f;
    
        @media ${device.tablet} {
            font-size:46px;
            line-height:52px;
        }
    }
    h3{
        font-size: 38px;
        line-height:44px;
        text-align:center;
        margin-bottom: 20px;
        color: #2f2f2f;
    
        @media ${device.laptopM} {
            font-size:34px;
            line-height:40px;
        }
    
        @media ${device.mobileXL} {
            font-size:30px;
            line-height:36px;
        }
    }
    h4{
        font-size:32px;
        line-height:38px;
        text-align:center;
        margin-bottom:20px;
        color: #2f2f2f;
    
        @media ${device.tablet} {
            font-size:28px;
            line-height:32px;
        }
    }
    h5{
        font-size:24px;
        line-height:30px;
        text-align:center;
        margin-bottom:20px;
        color: #2f2f2f;
        
    
        @media ${device.tablet} {
            font-size:22px;
            line-height:28px;
        }
    }
    h6{
        font-size:18px;
        line-height:24px;
        text-align:center;
        margin-bottom:20px;
        color: #2f2f2f;
    
        @media ${device.mobileXL} {
            font-size:17px;
            line-height:23px;
        }
    }
`;

export default GlobalStyle;