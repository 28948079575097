import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #bfca02;
    color: #fff;
    padding: 10px 40px;
    text-decoration:none;
    border-radius: 30px;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#d8e302;
        text-decoration:none;
        color:#fff;
    }

    @media ${device.mobileXL} {
        padding: 10px 30px;
    }
`;


export const SectionHeading = styled.h3`
    line-height:1;
    margin-bottom:10px;

    @media ${device.laptopM} {
        line-height:1;
    }

    @media ${device.mobileXL} {
        line-height:1;
    }
`;

export const SectionSubHeading = styled.p`
    line-height:1;
    text-align:center;
`;
