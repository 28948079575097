import React,{Component}from 'react';
import {FooterSection,FooterWrapper,ContactLayout,HeadingLayout,Heading,SubHeading,ImageHolder,
    CompanyDetailsLayout,CompanyLogo,
    ContactDetailsLayout,FormLayout,Form,FormGroup,InputText,ContactSpanErr,TextareaMessage,TextCenter,
    SubmitBtn,ContactSpanSuccess,DetailsLayout,DetailsIcon,FooterBottomLayout,
    FooterQuickLinksLayout,CustomRow,BottomFooterPara,
    BottomLink,SocialLayout,FooterSocial,FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,
    QuickLinksLayout,FooterUl,FooterLi
} from './footer.style';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const WRNG_MSG_TIMEOUT = 3000;

class Footer extends Component{

    constructor(props) {
        super(props);
        this.state = {
            contact_name:"",
            contact_email:"",
            contact_phone:"",
            contact_message:"",
            success_contact_message:"",
            errors:[]
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validateForm()
    {
        var error_flag = false;
        let errors = {};
        if(this.state.contact_name === "")
        {
            error_flag = true;
            errors['contact_name'] = "Please enter full name!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contact_email === "")
        {
            error_flag = true;
            errors['contact_email'] = "Please enter email!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contact_email !== "")
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.contact_email)) {
                error_flag = true;
                errors["contact_email"] = "Please enter valid email ID!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contact_phone === "")
        {
            error_flag = true;
            errors["contact_phone"] = "Please enter Mobile No!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }
        if(this.state.contact_phone !== "")
        {
            var patternPhone = new RegExp(/^\d+$/);
            if(!patternPhone.test(this.state.contact_phone)) {
                error_flag = true;
                errors["contact_phone"] = "Please enter valid Mobile No!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
            if(this.state.contact_phone.length<10) {
                error_flag = true;
                errors["contact_phone"] = "Please enter valid Mobile No!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contact_message === "")
        {
            error_flag = true;
            errors["contact_message"] = "Please enter message!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    contactUsSubmit = (e) =>{
        e.preventDefault();
        if(!this.validateForm())
        {
            //SUCCESS 
            //TO DO
            console.log("Name: "+this.state.contact_name);
            console.log("Email: "+this.state.contact_email);
            console.log("Phone: "+this.state.contact_phone);
            console.log("Message: "+this.state.contact_message);
            this.setState({
                contact_name:"",
                contact_email:"",
                contact_phone:"",
                contact_message:"",
                success_contact_message:"Thanks for contacting us.",
                errors:[]
            },()=>{
                setTimeout(() => {
                    this.setState({
                        success_contact_message:""
                    });
               },WRNG_MSG_TIMEOUT);
            });
        }
    }

    render() {
        return (
            <FooterSection id="footerSection">
                <FooterWrapper>
                    <Container>

                        <HeadingLayout>
                            <Heading>
                            {this.props.FooterData.Heading}
                            </Heading>
                            <SubHeading>
                            {this.props.FooterData.SubHeading}
                            </SubHeading>
                        </HeadingLayout>

                        <ContactLayout>
                            <Row>
                                <Col md={6}>
                                    
                                        <FormLayout>
                                            <Form method="post">
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <InputText aria-label="Name" type="text" placeholder="Name"  name="contact_name" onChange={this.handleChange} value={this.state.contact_name}/>
                                                            <ContactSpanErr>{this.state.errors.contact_name}</ContactSpanErr>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <InputText aria-label="Phone" type="tel" placeholder="Phone"  name="contact_phone" onChange={this.handleChange} value={this.state.contact_phone}/>
                                                            <ContactSpanErr>{this.state.errors.contact_phone}</ContactSpanErr>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <InputText aria-label="Email" type="email" placeholder="Email"  name="contact_email" onChange={this.handleChange} value={this.state.contact_email}/>
                                                            <ContactSpanErr>{this.state.errors.contact_email}</ContactSpanErr>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <TextareaMessage aria-label="Message" type="text" placeholder="Message" name="contact_message" onChange={this.handleChange} value={this.state.contact_message}></TextareaMessage>
                                                            <ContactSpanErr>{this.state.errors.contact_message}</ContactSpanErr>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12">
                                                        <TextCenter>
                                                            <SubmitBtn href="#" onClick={this.contactUsSubmit}>{this.props.FooterData.ContactButtonText}</SubmitBtn>
                                                        </TextCenter>
                                                        <ContactSpanSuccess>{this.state.success_contact_message}</ContactSpanSuccess>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </FormLayout>
                                    
                                </Col>
                                <Col md={6}>
                                    <ImageHolder>
                                        <GatsbyImage
                                            image={this.props.FooterData.MapImage.childImageSharp.gatsbyImageData}
                                            alt=""
                                            className="mapImage" />
                                    </ImageHolder>
                                </Col>
                            </Row>
                        </ContactLayout>

                        <FooterBottomLayout>
                            <Row>
                                <Col md={6}>
                                    <CompanyDetailsLayout>
                                        <CompanyLogo src={this.props.FooterData.CompanyLogo} alt="" />
                                        <p>
                                        {this.props.FooterData.AboutText}
                                        </p>
                                    </CompanyDetailsLayout>
                                </Col>
                                <Col md={6}>
                                    <ContactDetailsLayout>
                                        {
                                            this.props.FooterData.DetailsData.map((footerItem, footerIdx)=>{
                                                return <DetailsLayout>
                                                    <DetailsIcon src={footerItem.DetailsIcon} alt=""/>
                                                    <p>
                                                    {footerItem.DetailsText}
                                                    </p>
                                                </DetailsLayout>
                                            })
                                        }
                                    </ContactDetailsLayout>
                                </Col>
                            </Row>
                        </FooterBottomLayout>
                    </Container>
                </FooterWrapper>

                <FooterQuickLinksLayout>
                    <Container>
                        <CustomRow>
                            <Col lg="3">
                                <BottomFooterPara>
                                    {this.props.FooterData.BottomFooterPara}
                                    <BottomLink rel="noreferrer" href={this.props.FooterData.BottomFooterLink} target="_blank">
                                    {this.props.FooterData.BottomFooterName}
                                    </BottomLink>
                                </BottomFooterPara>
                            </Col>
                            <Col lg="6">
                                <QuickLinksLayout>
                                    <FooterUl>
                                        {
                                            this.props.FooterData.FooterUl.map((footerMenu, menuIdx) => {
                                                return <FooterLi>
                                                    <AnchorLink offset={55} href={footerMenu.Href}>
                                                    {footerMenu.Menu}
                                                    </AnchorLink>
                                                </FooterLi>
                                            })
                                        }
                                    </FooterUl>
                                </QuickLinksLayout>
                            </Col>

                            <Col lg={3}>
                                <SocialLayout>
                                    <FooterSocial href="#" aria-label={"Fb Link"}>
                                        <FbIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="#" aria-label={"Twitter Link"}>
                                        <TwitterIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="#" aria-label={"Linkedin Link"}>
                                        <LinkedinIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="#" aria-label={"Instagram Link"}>
                                        <InstaIcon/>
                                    </FooterSocial>
                                </SocialLayout>
                            </Col>
                        </CustomRow>
                    </Container>
                </FooterQuickLinksLayout>
                
            </FooterSection>
        );
    }
}
export default () => (
    <StaticQuery
        query={graphql`{
  realestatePage5Json {
    Footer {
      Heading
      SubHeading
      ContactButtonText
      MapImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      CompanyLogo
      AboutText
      DetailsData {
        DetailsIcon
        DetailsText
      }
      FooterUl {
        Href
        Menu
      }
      BottomFooterPara
      BottomFooterName
      BottomFooterLink
    }
  }
}
`}
        render={(data) => (
            <Footer FooterData={data.realestatePage5Json.Footer}/>
        )}
    />
  )