import React from 'react';
import {NeighbourSection,SectionWrapper,HeadingLayout,SubHeading,Heading,
    NeighbourhoodLayout,AccordianWrapper,ImageHolder
} from './neighbour.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';
import AccordianCustom from './AccordianCustom';

const Neighbour = () => {
    const JSONData  = useStaticQuery(graphql`{
  realestatePage5Json {
    Neighbour {
      SubHeading
      Heading
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      AccordianCustom {
        title
        description
      }
    }
  }
}
`);
    const NeighbourData = JSONData.realestatePage5Json.Neighbour;

        return (
            <NeighbourSection id="neighbourSection">
                <SectionWrapper>
                    <Container>
                        <HeadingLayout>
                            <Heading>
                                {NeighbourData.Heading}
                            </Heading>
                            <SubHeading>
                                {NeighbourData.SubHeading}
                            </SubHeading>
                        </HeadingLayout>

                        <NeighbourhoodLayout>
                            <AccordianWrapper>
                            {
                                NeighbourData.AccordianCustom.map((item,idx) => {
                                return <AccordianCustom 
                                    title={item.title}
                                    description= {item.description} 
                                />
                                })
                            }
                            </AccordianWrapper>
                            <ImageHolder>
                                <GatsbyImage
                                    image={NeighbourData.Img.childImageSharp.gatsbyImageData}
                                    alt=""
                                    className="neighbourhoodImg" />
                            </ImageHolder>
                        </NeighbourhoodLayout>
                    </Container>
                </SectionWrapper>
            </NeighbourSection>
        );
}

export default Neighbour;