import React,{Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AmenitiesSection,
    SectionWrapper,
    HeadingLayout,
    Heading,
    SubHeading,
    CustomRow,
    AmenitiesLayout,
    AmenitiesImage,
    SliderWrapper,
    ImgButtonLeft,
    LeftIcon,
    SliderLayout,
    ImgButtonRight,
    RightIcon
} from './amenities.style';
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage } from "gatsby-plugin-image";

class AmenitiesPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
        };
        
        return (
            <AmenitiesSection id="amenitiesSection">
                <SectionWrapper>
                    <Container>
                        <CustomRow>
                            <Col lg={7}>
                                <HeadingLayout>
                                    <Heading>
                                    {this.props.AmenitiesData.Heading}
                                    </Heading>
                                    <SubHeading>
                                    {this.props.AmenitiesData.SubHeading}
                                    </SubHeading>
                                </HeadingLayout>
                            <Row>
                            {
                                this.props.AmenitiesData.AmenitiesData.map((amnObj, idx) => {
                                return <Col md={6} sm={6}>
                                    <AmenitiesLayout>
                                        <AmenitiesImage src={amnObj.Img} alt="" />
                                        <h6>
                                        {amnObj.AmenitiesText}
                                        </h6>
                                    </AmenitiesLayout>
                                </Col>
                                })
                            }
                            </Row>
                            </Col>
                            <Col lg={5}>
                                <SliderWrapper>
                                    <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                                        <LeftIcon />
                                    </ImgButtonLeft>
                                    <Slider ref={c => (this.slider = c)} {...settings}>
                                    {
                                        this.props.AmenitiesData.Slider.map((itemImg,idxPanel) => {
                                        return (
                                            <SliderLayout>
                                                        <GatsbyImage
                                                            image={itemImg.Image.childImageSharp.gatsbyImageData}
                                                            alt=""
                                                            className="amenitiesImg" />
                                                    </SliderLayout>
                                        );
                                            })
                                    }
                                    </Slider>
                                    <ImgButtonRight onClick={this.next} aria-label="Next Button">
                                        <RightIcon />
                                    </ImgButtonRight>
                                </SliderWrapper>
                            </Col>
                        </CustomRow>
                    </Container>
                </SectionWrapper>
            </AmenitiesSection>
        );
    }
}


export default () => (
    <StaticQuery
    query={graphql`{
  realestatePage5Json {
    Amenities {
      SubHeading
      Heading
      AmenitiesData {
        Img
        AmenitiesText
      }
      Slider {
        Image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`}

    render={(data) => (
        <AmenitiesPage AmenitiesData={data.realestatePage5Json.Amenities}/>
    )}
    />
)