import React,{Component} from "react";
import {NavbarCustom,NavbarToggleCustom,BrandLogo,PhoneCallIcon,EmailIcon
   } from "./headermenubs.style"
import {
    Container,Nav,Navbar
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Headermenu extends Component{
    constructor(props){
        super(props);
        this.state = { 
            stickyClass:'top',
        };
    }

    componentDidMount(){
        window.addEventListener('scroll', () => {
            let stickyClass = 'topSticky';
            let scrollPos = window.scrollY;

            if(scrollPos < 100){
                stickyClass = 'top';
            }

            if(this.state.stickyClass !== stickyClass)
            {
                this.setState({ stickyClass });
            }
                
        });
    }

    componentWillUnmount() { 
        window.removeEventListener('scroll'); 
    } 

    render(){
        return(
            <NavbarCustom 
                expand="lg" fixed="top" 
                isSticky={this.state.stickyClass==="topSticky"?true:false}>
                <Container>
                    <Navbar.Brand href="/">
                        <BrandLogo src={this.state.stickyClass==="topSticky"?this.props.HeaderData.BrandLogoSticky:this.props.HeaderData.BrandLogo}
                        isSticky={this.state.stickyClass==="topSticky"?true:false}
                        alt="Logo"
                        />
                    </Navbar.Brand>

                    <NavbarToggleCustom
                    isSticky={this.state.stickyClass==="topSticky"?true:false}
                    aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <ul>
                                <li>
                                    <a 
                                        className="mailphone"
                                        href={"mailto:"+this.props.HeaderData.email}>
                                        <EmailIcon />
                                        {this.props.HeaderData.email}
                                    </a>
                                </li>
                                <li>
                                    <a 
                                        className="mailphone"
                                        href={"tel:"+this.props.HeaderData.phone}>
                                        <PhoneCallIcon />
                                        {this.props.HeaderData.phone}
                                    </a>
                                </li>
                                <li>
                                    <AnchorLink 
                                        offset={55} 
                                        className="contactBtn"
                                        href={this.props.HeaderData.contact.Href}
                                    >
                                        {this.props.HeaderData.contact.Menu}
                                    </AnchorLink>
                                </li>
                            </ul>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </NavbarCustom>
            
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query{
                realestatePage5Json {
                    Header{
                        BrandLogo
                        BrandLogoSticky
                        email
                        phone
                        contact{
                            Menu
                            Href
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <Headermenu HeaderData={data.realestatePage5Json.Header}/>
        )}
    />
  )