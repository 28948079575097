import React,{Component} from 'react';
import {GallerySection,GalleryLayout,HeadingLayout,SubHeading,Heading,ImgButtonLeft,LeftIcon,
    GalleryImageWrapper,ImgButtonRight,RightIcon
} from './gallery.style';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Gallery extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
    
    render() {
        const settings = {
            arrows:false,
            dots:false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            rows:1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                  breakpoint: 575,
                  settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        rows:1
                    }
                },
                {
                  breakpoint: 425,
                  settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        rows:1
                    }
                }
            ]
        };
        return (
            <GallerySection id="gallerySection">
                
                <HeadingLayout>
                    <Heading>
                        {this.props.GalleryData.Heading}
                    </Heading>
                    <SubHeading>
                        {this.props.GalleryData.SubHeading}
                    </SubHeading>
                </HeadingLayout>

                <GalleryLayout>
                    <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                        <LeftIcon />
                    </ImgButtonLeft>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                    {
                        this.props.GalleryData.Slider.map((item,idx) => {
                            return (
                                <GalleryImageWrapper onClick={this.openDialogBox.bind(this,this.props.GalleryData,idx)}><GatsbyImage
                                    image={item.GallerySliderImg.childImageSharp.gatsbyImageData}
                                    alt=""
                                    className="galleryImg" />
                                </GalleryImageWrapper>
                            );
                        })
                    }
                    </Slider>
                    <ImgButtonRight onClick={this.next} aria-label="Next Button">
                        <RightIcon />
                    </ImgButtonRight>
                </GalleryLayout>
            </GallerySection>
        );
    }
}

export default props => (
<StaticQuery
    query={graphql`{
  realestatePage5Json {
    Gallery {
      SubHeading
      Heading
      Slider {
        GallerySliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
          publicURL
        }
      }
    }
  }
}
`}
    render={(data) => (
        <Gallery 
        GalleryData={data.realestatePage5Json.Gallery}
        {...props}
        />
    )}
/>
)
