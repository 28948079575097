import React from 'react';
import {AboutSection,SectionWrapper,CustomRow,LeftLayout,HeadingLayout,Heading,SubHeading,
    ServicesLayout,ServicesImg,
    ServicesTextLayout,ImageHolder
} from './about.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`{
  realestatePage5Json {
    About {
      Heading
      SubHeading
      Description1
      Description2
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      ServicesImg1
      ServicesHeading1
      ServicesDesc1
      ServicesImg2
      ServicesHeading2
      ServicesDesc2
    }
  }
}
`);
    const AboutData = JSONData.realestatePage5Json.About;
    
    return (
        <AboutSection id="aboutSection">
            <SectionWrapper>
                <Container>
                    <CustomRow>
                        <Col lg={6}>
                            <LeftLayout>
                                <HeadingLayout>
                                    <Heading>
                                        {AboutData.Heading}
                                    </Heading>
                                    <SubHeading>
                                        {AboutData.SubHeading}
                                    </SubHeading>
                                </HeadingLayout>
                                <p>
                                {AboutData.Description1} 
                                </p>
                                <p>
                                {AboutData.Description2} 
                                </p>
                            
                                <Row>
                                    <Col md={6} sm={6} xs={12}>
                                        <ServicesLayout>
                                            <ServicesImg 
                                                src={AboutData.ServicesImg1} 
                                                alt=""
                                            />
                                            <ServicesTextLayout>
                                                <h5>
                                                {AboutData.ServicesHeading1} 
                                                </h5>
                                                <p>
                                                {AboutData.ServicesDesc1}
                                                </p>
                                            </ServicesTextLayout>
                                        </ServicesLayout>
                                    </Col>
                                    <Col md={6} sm={6} xs={12}>
                                        <ServicesLayout>
                                            <ServicesImg 
                                                src={AboutData.ServicesImg2} 
                                                alt=""
                                            />
                                            <ServicesTextLayout>
                                                <h5>
                                                {AboutData.ServicesHeading2} 
                                                </h5>
                                                <p>
                                                {AboutData.ServicesDesc2}
                                                </p>
                                            </ServicesTextLayout>
                                        </ServicesLayout>
                                    </Col>
                                </Row>
                            </LeftLayout>
                        </Col>
                        <Col lg={6}>
                            <ImageHolder>
                                <GatsbyImage image={AboutData.Img.childImageSharp.gatsbyImageData} alt="" />
                            </ImageHolder>
                        </Col>
                    </CustomRow>
                </Container>
            </SectionWrapper>
        </AboutSection>
    );
}

export default About;